<template>
  <el-main>
    <img alt="GNY logo" src="../assets/logo.jpeg" />
    <h3>Step 1: Generate a random account</h3>

    <el-button type="info" @click="newAccount">Generate Account</el-button>

    <el-container direction="vertical">
      <label for="phrase" v-if="!!secret">Mnemonic</label>

      <el-input v-model="secret" readonly v-if="!!secret"></el-input>

      <label for="address" v-if="!!address">Address</label>

      <el-input v-model="address" readonly v-if="!!address"></el-input>

      <label for="publicKey" v-if="!!publicKey">Public Key</label>

      <el-input v-model="publicKey" readonly v-if="!!publicKey"></el-input>
    </el-container>
    <hr />

    <h3>Step 2: Submit your address</h3>

    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          @click="sendTransaction"
          :disabled="currentlySendingTransactions"
        >Get GNY Token!</el-button>
      </el-form-item>
    </el-form>

    <hr />
    <div>
      <div>
        <h3>Generate P2P key</h3>
      </div>
      <el-button type="info" @click="p2pkey">Generate Key</el-button>
    </div>

    <el-container direction="vertical">
      <label for="id" v-if="!!id">ID</label>
      <el-input v-model="id" readonly v-if="!!id"></el-input>

      <label for="passphrase" v-if="!!privKey">Private Key</label>
      <el-input type="textarea" v-model="privKey" readonly v-if="!!privKey"></el-input>
    </el-container>
    <hr />
    <h3>Previous Transactions</h3>
    <el-table :data="transactions" stripe style="width: 80%; margin: auto;">
      <el-table-column prop="date" align="center" label="Date" width="200"></el-table-column>
      <el-table-column prop="address" align="center" label="Address" width="300"></el-table-column>
      <el-table-column prop="amount" align="center" label="Amount" width="180"></el-table-column>
      <el-table-column prop="transactionId" align="center" label="Transaction ID"></el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next"
        :total="transactionsCount"
      ></el-pagination>
    </div>
  </el-main>
</template>

<script>
import * as PeerInfo from "peer-info";
import * as Mnemonic from "bitcore-mnemonic";
import axios from "axios";
import { generateAddress, isAddress } from "@gnyio/utils";
import { getKeys } from "@gnyio/web-base";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      secret: "",
      address: "",
      publicKey: "",
      id: "",
      privKey: "",
      form: {
        address: ""
      },
      recipient: "",
      count: 0,
      currentlySendingTransactions: false,

      transactions: [],
      transactionsCount: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },

  methods: {
    async handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      let from = (currentPage - 1) * this.pageSize;
      // let to = currentPage * this.pageSize;

      const request = await axios.get(`/.netlify/functions/serverless-http?limit=${this.pageSize}&offset=${from}`);
      const trs = request.data.transactions;

      // change TAB 
      // clear array by keeping the same reference
      // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
      this.transactions.splice(0, this.transactions.length);
      this.transactions.push(...trs);
    },
    async newAccount() {
      try {
        this.secret = new Mnemonic(Mnemonic.Words.ENGLISH).toString();
        const keypair = getKeys(this.secret);

        this.publicKey = keypair.publicKey;
        this.address = generateAddress(this.publicKey);
      } catch (error) {
        console.log(error);
      }
    },

    async p2pkey() {
      try {
        const peerInfo = await PeerInfo.create();
        const jsonId = peerInfo.id.toJSON();
        this.id = jsonId.id;
        this.privKey = jsonId.privKey;
      } catch (error) {
        console.log(error);
      }
    },

    async sendTransaction() {
      this.currentlySendingTransactions = true;

      const address = this.form.address;

      console.log(`address "${address}`);

      if (!isAddress(address)) {
        this.$message({
          showClose: true,
          message: `Address "${address}" is not a valid address`,
          type: "error"
        });
        return;
      }
      
      try {
        this.$message({
          showClose: true,
          message: "Token has been sent to your address!",
          type: "success"
        });
        const url = `/.netlify/functions/serverless-http?address=${address}`;
        console.log(`url: "${url}"`)
        await axios.post(url);
      } catch (error) {
        console.log(error && error.response && error.response.data);
      } finally {
        await sleep(2000);
        this.currentlySendingTransactions = false;
      }
    }
  },

  mounted: async function () {
      try {
        const response = await axios.get(`/.netlify/functions/serverless-http`);

        const trs = response.data.transactions;
        const count = response.data.count;

        this.transactionsCount = count;
        this.transactions.push(...trs);
      } catch (err) {
          console.log(err);
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  text-align: center;
}

.el-input {
  width: 30%;
  margin: 10px auto;
}

hr {
  width: 40%;
}

img {
  width: 300px;
}

label {
  margin: 5px;
  font-size: 18px;
}

.el-alert {
  width: 500px;
}

h3 {
  margin: 40px 0 0;
}

.el-button {
  margin: 20px;
}

.el-form {
  margin: 20px;
}
</style>
